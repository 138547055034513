$bg-img: url( '../assets/images/bg_circle.svg');

a {
    text-decoration: none;
}

.bg-light {
    background-image: $bg-img;
    background-position: fixed;
    background-size: cover;
    background-color: #fff;
    // background: rgb(93,91,212);
    // background: linear-gradient(22deg, rgba(93,91,212,.2) 0%, rgba(255,255,255,.2) 100%);
    // height: 100%;
}

img.sidebar-brand-full {
    margin-left: 30px;
}

.tcf-loading-background {
    height: 100%;
    width: 100%;
    background-color: #fff;
    opacity: 0.9;
    position: fixed;
    padding-top: 300px;
    z-index: 100;
    top: 0px;
    left: 0px;
}

.tcf-header {
    margin: 20px;
    border-radius: 10px;
    box-shadow: 1px -2px 33px -15px rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px -2px 33px -15px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px -2px 33px -15px rgba(0,0,0,0.75);
    position: fixed;
    --cui-header-padding-y: 0;
}

.tcf-callqueue-select {
    width: 300px;
}

.tcf-welcome-person {
    display: inline-block;
}

.tcf-welcome-person {
    --person-avatar-size: 0px;
    --person-line1-text-color: rgba(44, 56, 74, 0.95);
}

.tcf-welcome-person .person-root {
    display: block;
}

.tcf-card-img {
    height: 207px;
    object-fit: cover;
}

.tcf-acc-voiceapp {
    padding: 5px;
    min-height: 50px;
}

.tcf-card-logo {
    padding: 15px;
    padding-right: 35px;
    padding-top: 25px;
    width: 100%;
    object-fit: cover;
}

.tcf-global-nav {
    display: flex;
    position: absolute;
    flex: initial;
    flex-wrap: wrap;
    margin-top: 60px;
    margin-bottom: 0px;
    width: 900px;
    margin-left: 200px;
}

.tcf-global-nav li {
    float: left;
    margin-left: 5%;
    padding-bottom: 30px;
}

.tcf-teams-channel-info {
    margin-top: -20px;
    color: #bababa !important;
}

.tcf-setup-tabpane {
    padding: 20px;
}

.nav-item {
    display: inline;
}

.nav-link {
    font-weight: 500 !important;
    padding-bottom: 30px !important;
}

.active {
    border-bottom-color: #4F5D73 !important;
    border-bottom-width: 4px !important;
    border-bottom-style: solid !important;    
    padding-bottom: 30px !important;
}

.tcf-danger {
    color: #e55353;
}

.footer .btn-link {
    margin: 0px;
    margin-top: -5px;
    padding: 0px;
}

.fui-Combobox {
    width: 100%;
}

.tcf-ed-welcome-wallpaper {
    object-fit: cover;
    height: 250px;
}